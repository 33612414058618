import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useContext } from 'react';
import { PricingToggleContext } from '../../contexts/pricingToggleContext';
import Badge from '../../molecules/Badge/Badge';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import WaveBg from '../../molecules/Section/SectionBg/WaveBg';
import { breakpoints } from '../../styles/atoms/breakpoints';
import { colors } from '../../styles/atoms/colors';
import ListItem from '../ListItems';
import {
  Body,
  CardTitle,
  Content,
  Heading,
  ListContainer,
  Price,
  PriceWrapper,
  Subhead,
  Wrapper,
} from './styles/PricingAddOnCard.styled';
import { getSpanishText } from '../../utils/temporalSpanishManagement';

const PricingAddOnCard = ({ component, slug }) => {
  const {
    annualPrice,
    background,
    cardTitle,
    cta,
    listContent,
    monthlyPrice,
    subhead,
    tagLabel,
  } = component;
  const { isAnnualPlan } = useContext(PricingToggleContext);
  const isMobile = !useMediaQuery(`(min-width: ${breakpoints.sm})`);

  const PriceComponent = ((isAnnualPlan && annualPrice) ||
    (!isAnnualPlan && monthlyPrice)) && (
    <PriceWrapper>
      $<Price>
        {isAnnualPlan ? annualPrice : monthlyPrice}
      </Price>/
      {getSpanishText(slug).PRICING.MONTH}
    </PriceWrapper>
  );

  return (
    <Wrapper background={background}>
      <WaveBg />
      <Body>
        <Heading>
          <Content>
            {tagLabel && (
              <Badge
                size="sm"
                badgeColor={
                  background === 'Charcoal'
                    ? colors.primary[100]
                    : colors.aqua[700]
                }
                textColor={
                  background === 'Charcoal'
                    ? colors.primary[500]
                    : colors.aqua[100]
                }
              >
                {tagLabel}
              </Badge>
            )}
            {cardTitle && <CardTitle>{cardTitle}</CardTitle>}
            {isMobile && PriceComponent}
          </Content>
          {!isMobile && PriceComponent}
        </Heading>
        {subhead && <Subhead>{subhead}</Subhead>}
        <ListContainer>
          <ListItem items={listContent} pricing />
        </ListContainer>
      </Body>
      <CallToAction
        className="pricing-btn"
        variant="outlineWhite"
        link={cta?.url}
        value={getSpanishText(slug).PRICING.REQUEST_DEMO}
      />
    </Wrapper>
  );
};

export default PricingAddOnCard;
